@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

* {
  font-family: Inter;
  transition-duration:  150ms;
}

.slide-left{
  animation: slide-left-anim 0.6s ease;
}

@-webkit-keyframes slide-left-anim {
  from {
    transform: translateX(-32px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-left-anim {
  from {
    transform: translateX(-32px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
.bg {
  background-color: #333333;
  opacity: 0.8;
  background-image: radial-gradient(circle at center center, #818181, #333333), repeating-radial-gradient(circle at center center, #818181, #818181, 4px, transparent 8px, transparent 4px);
  background-blend-mode: multiply;
}